/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import Swiper from 'react-id-swiper'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import ModalHome from '../components/ModalHome'
import CardService from '../components/CardService/index'
import Pagina from '../components/Pagina'

// SCSS/CSS
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'swiper/css/swiper.css'
import * as styles from './styles/index.module.scss'

// Assets
import infografico from '../images/home/graficohome.svg'
import infograficoEnglish from '../images/home/infografico-english.svg'
import infograficoEspanhol from '../images/home/infografico-espanhol.svg'
import circleArrow from '../images/icon/home/circleArrow.svg'

// Cards
import icon1 from '../images/icon/home/Home_01.svg'
import icon2 from '../images/icon/home/Home_02.svg'
import icon3 from '../images/icon/home/Home_03.svg'
// import close from '../images/icon/home/close.svg'

// import sertoes from '../images/vilao-oluc/SERTOES.png'
import vilao from '../images/vilao-oluc/VILAO.png'
import oleo from '../images/vilao-oluc/OLEO_ESCORRENDO.png'
import oluc from '../images/vilao-oluc/OLUC.png'
import lettering from '../images/vilao-oluc/LETTERING.png'
import close from '../images/vilao-oluc/X_FECHAR.png'

import vilaoMobile from '../images/vilao-oluc/VILAO_MOBILE.png'
import oleoMobile from '../images/vilao-oluc/OLEO_ESCORRENDO_MOBILE.png'
import letteringMobile from '../images/vilao-oluc/LETTERING_MOBILE.png'
import { label } from 'aws-amplify'

const params = {
  autoplay: false,
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  spaceBetween: 1,
  slidesPerView: 1
}

const HomeCarousel = (data) => {
  const [abrirModal, setAbrirModal] = useState(false)
  const intl = useIntl()

  const [showElement, setShowElement] = useState(false)

  function closeFunction () {
    setShowElement(!showElement)
    window.localStorage.setItem('gifPreview', new Date().toDateString())
  }

  useEffect(() => {
    const lastViewDate = window.localStorage.getItem('gifPreview')
    const currentDate = new Date().toDateString()

    if (!lastViewDate || lastViewDate !== currentDate) {
      setTimeout(() => {
        setShowElement(true)
      }, 0)
    }
  }, [])

  return (
    <section className="full m-0">
      <ModalHome setActive={setAbrirModal} active={abrirModal} />
      <div id="carousel-swipper" className={styles.swiperContainer}>
        {typeof window !== 'undefined' && window.localStorage.getItem('gifPreview') === 'true'
          ? <div className='d-none'></div>
          : <div>
            {showElement === true
              ? <>
                <div className={`d-lg-block d-none position-absolute ${styles.backgroundVilao}`}>
                  <img onClick={() => closeFunction()} className={`position-absolute ${styles.closeGif} ${styles.fadeInCloseGif}`} src={close} />
                  <Link to='/blog/oluc-conheca-o-vilao-ambiental/' onClick={() => {
                    if (window.dataLayer) {
                      console.log('Evento de clique acionado')
                      window.dataLayer.push({
                        event: 'click',
                        category: 'Banner',
                        action: 'Click',
                        label: 'Oluc Vilao Ambiental'
                      })
                    }
                  }}>
                    <img className={`position-absolute ${styles.vilaoOluc} ${styles.fadeInDown}`} src={oleo} />
                    <img className={`position-absolute ${styles.text} ${styles.fadeInText}`} src={oluc} />
                    <img className={`position-absolute ${styles.textTwo} ${styles.fadeInTextTwo}`} src={lettering} />
                    <img className={`position-absolute ${styles.vilaoOluc} ${styles.fadeInDown}`} src={vilao} />
                  </Link>
                </div>
                <div className={`d-block d-lg-none position-absolute ${styles.backgroundVilaoMobile}`}>
                  <img onClick={() => closeFunction()} className={`position-absolute ${styles.closeGif} ${styles.fadeInCloseGif}`} src={close} />
                  <Link to='/blog/oluc-conheca-o-vilao-ambiental/' onClick={() => {
                    if (window.dataLayer) {
                      console.log('Evento de clique acionado')
                      window.dataLayer.push({
                        event: 'click',
                        category: 'Banner',
                        action: 'Click',
                        label: 'Oluc Vilao Ambiental'
                      })
                    }
                  }}>
                    <img className={`position-absolute ${styles.vilaoOlucMobile} ${styles.fadeInDown}`} src={oleoMobile} />
                    <img className={`position-absolute ${styles.textMobile} ${styles.fadeInTextMobile}`} src={letteringMobile} />
                    <img className={`position-absolute ${styles.vilaoOlucMobile} ${styles.fadeInDown}`} src={vilaoMobile} />
                  </Link>
                </div>
                {/* <div className={`d-lg-block d-none position-absolute ${styles.backgroundSertoes}`}>
                  <img onClick={() => closeFunction()} className={`position-absolute ${styles.closeGif} ${styles.fadeInCloseGif}`} src={close} />
                  <img className={`position-absolute ${styles.sertoes} ${styles.fadeInDown}`} src={sertoes} />
                  <p className={`position-absolute ${styles.textContainer} ${styles.fadeInText}`}>
                    <span className={styles.boldSertoes}>SERTÕES <span className={styles.yearText}>2024</span></span>
                    <span className={styles.boldLwart}>COM <span>LWART!</span></span><br />
                    <span className={styles.sertoesLink}>
                      <a href='https://www.instagram.com/lwartsolucoesambientais?igsh=dzMyMGxndjkzNTlt&utm_source=qr'
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                          if (window.dataLayer) {
                            console.log('Evento de clique acionado')
                            window.dataLayer.push({
                              event: 'click',
                              category: 'Banner',
                              action: 'Click',
                              label: 'Acompanhe nas Redes Sociais'
                            })
                          }
                        }}
                      >
                        Acompanhe nas nossas redes sociais
                      </a>
                    </span>
                  </p>
                </div> */}
                {/* <div className={`d-block d-lg-none position-absolute ${styles.backgroundSertoesMobile}`}>
                  <img onClick={() => closeFunction()} className={`position-absolute ${styles.closeGif} ${styles.fadeInCloseGif}`} src={close} />
                  <img className={`position-absolute ${styles.sertoesMobile} ${styles.fadeInDown}`} src={sertoes} />
                  <p className={`position-absolute ${styles.textContainerMobile} ${styles.fadeInText}`}>
                    <span className={styles.boldSertoesMobile}>SERTÕES <span className={styles.yearTextMobile}>2024</span></span>
                    <span className={styles.boldLwartMobile}>COM <span>LWART!</span></span><br />
                    <span className={styles.sertoesLinkMobile}><a href='https://www.instagram.com/lwartsolucoesambientais?igsh=dzMyMGxndjkzNTlt&utm_source=qr' target='_blank' rel="noreferrer">acompanhe nas nossas redes sociais</a></span>
                  </p>
                </div> */}
              </>
              : <div className='d-none'></div>
            }
          </div>
        }
        <Swiper {...params} >
          <div className={`w-100 position-relative d-flex justify-content-center align-items-center ${styles.swiperContainer}`} >
            <div className={`hero container justify-content-center position-absolute d-grid row ${styles.bannerTextSustentabilidade} d-lg-flex d-none`}>
              <h2 className="text-white position-relative banner-sustentabilidade-bold">{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.title' }))}</h2>
              <div className='container d-flex justify-content-end'>
                <div className='d-flex justify-content-end' style={{ width: '100%', position: 'relative', top: 17 + 'em', left: 3 + 'em' }}>
                  <div>
                    <h4 className='text-lwart-darkblue'>{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.text' }))}</h4>
                    <Link to="/sustentabilidade/" className="text-center" onClick={() => {
                      if (window.dataLayer) {
                        console.log('Evento de clique acionado')
                        window.dataLayer.push({
                          event: 'click',
                          category: 'Banner',
                          action: 'Click',
                          label: 'Relatorio Sustentabilidade'
                        })
                      }
                    }}>
                      <button type="button" className={`btn ${styles.btnBannerSustentabilidade} w-55 mx-auto`}>
                        {intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.button' })}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={`hero container justify-content-start position-absolute d-grid row ${styles.bannerTextSustentabilidade} d-lg-none d-flex`}>
              <div className='mt-3'>
                <h5 className="text-white position-relative w-60"><a href="/sustentabilidade" style={{ color: 'white', textDecoration: 'none' }}>{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.title' }))}</a></h5>
                <h6 className='text-lwart-darkblue w-65 mt-3'>{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.textMobile' }))}</h6>
              </div>
              <div className='container d-flex justify-content-center align-items-end mb-4'>
                <Link to="/sustentabilidade/" className="text-center" onClick={() => {
                  if (window.dataLayer) {
                    console.log('Evento de clique acionado')
                    window.dataLayer.push({
                      event: 'click',
                      category: 'Banner',
                      action: 'Click',
                      label: 'Relatorio Sustentabilidade'
                    })
                  }
                }}>
                  <button type="button" className={`btn ${styles.btnBannerSustentabilidade} w-100 mx-auto`}>
                    {intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.button' })}
                  </button>
                </Link>

                {/* <div className='d-flex align-itens-end justify-content-center' style={{ width: '100%', position: 'relative', bottom: 0 + 'px' }}>
                  <div>
                    <Link to="/sustentabilidade/" className="text-center"><button type="button" className={`btn ${styles.btnBannerSustentabilidade} w-100 mx-auto`}> {intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.button' })}</button></Link>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className={`hero position-absolute d-grid row ${styles.bannerTextSustentabilidade} d-lg-none d-block`}>
              <a href="/sustentabilidade" style={{ color: 'white', textDecoration: 'none' }}><h5 className="text-white">{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop.title' }))}</h5></a>
              <div className={`w-100 position-relative d-flex align-items-center justify-content-start ${styles.swiperContainer}`} >
                <div className="hero position-absolute d-grid" style={{ left: '-5em', top: '4em', width: '500px' }}>
                </div>
              </div>
            </div> */}
            <div className="w-100">
              <GatsbyImage
                image={data.Carrossel1.childImageSharp.gatsbyImageData}
                className="img-fluid d-lg-block d-none" alt='imagem banner' />
              <GatsbyImage
                image={data.bannerPrincipalMobile.childImageSharp.gatsbyImageData}
                className="img-fluid d-lg-none d-block" alt='imagem banner' />
            </div>
          </div>
          <div className={`w-100 position-relative d-flex align-items-center ${styles.swiperContainer}`} >
            <div className={`hero position-absolute d-grid row ${styles.bannerText} d-lg-block d-none`}>
              <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.title2' }))}</h1>
              <p className="text-white">
                {parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.p12' }))}
                <Link to="/nossas-solucoes/" className="text-white" onClick={() => {
                  if (window.dataLayer) {
                    console.log('Evento de clique acionado')
                    window.dataLayer.push({
                      event: 'click',
                      category: 'Banner',
                      action: 'Click',
                      label: 'Nossas soluções'
                    })
                  }
                }}>
                  <u>{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.link2' }))}</u>
                </Link>.
                <br />
              </p>
            </div>
            <div className={`hero position-absolute d-grid row ${styles.bannerText} d-lg-none d-block`}>
              <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.title2' }))}</h1>l
              <p className="text-white">
                {parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.p12' }))}
                <Link to="/nossas-solucoes/" className="text-white" onClick={() => {
                  if (window.dataLayer) {
                    console.log('Evento de clique acionado')
                    window.dataLayer.push({
                      event: 'click',
                      category: 'Banner',
                      action: 'Click',
                      label: 'Nossas soluções'
                    })
                  }
                }}>
                  <u>{parse(intl.formatMessage({ id: 'paginas.index.home_carousel.desktop2.link2' }))}</u>
                </Link>.
                <br />
              </p>
            </div>
            <div className="w-100">
              <GatsbyImage
                image={data.Carousel1.childImageSharp.gatsbyImageData}
                className="img-fluid d-lg-block d-none" alt='imagem banner' />
              <GatsbyImage
                image={data.Carousel1Mobile.childImageSharp.gatsbyImageData}
                className="img-fluid d-lg-none d-block" alt='imagem banner' />
            </div>
          </div>
          <div className="w-100 d-lg-block d-none">
            <video autoPlay muted loop className={styles.swiperContainerVideo} id="video-play">
              <source src='https://d29gz1j979b5he.cloudfront.net/LWART_NOVA_MARCA_clip1_reduzido.mp4' type="video/mp4" />
            </video>
          </div>
        </Swiper>
      </div>
    </section>
  )
}

const FloatingInfo = () => {
  const intl = useIntl()

  return (
    <>
      {intl.locale === 'pt'
        ? <div className={styles.infoItems}>
          <Link to='/coleta-de-oleo-lubrificante-usado/'>
            <div className={styles.blue}>
              <img src={icon1} />
            </div>
            <div className={styles.textBlue}>
              <p className='text-white'>{parse(intl.formatMessage({ id: 'paginas.index.floating_info.blue' }))}</p>
            </div>
            <div className='d-none'></div>
          </Link>
          <Link to='/gestao-de-residuos/'>
            <div className={styles.green}>
              <img src={icon3} />
            </div>
            <div className={styles.textGreen}>
              <p className='text-white'>{parse(intl.formatMessage({ id: 'paginas.index.floating_info.green' }))}</p>
            </div>
            <div className='d-none'></div>
          </Link>
        </div>
        : <div className='d-none'></div>
      }
    </>
  )
}

const Solutions = () => {
  const [showMore, setShowMore] = useState()
  const intl = useIntl()

  const ShowMore = (id) => {
    setShowMore(id)
  }

  return (
    <section className="full m-0">
      <div className="container py-5 my-lg-4">
        <header className="mb-3">
          <h1 className="text-lwart-blue mb-0">{parse(intl.formatMessage({ id: 'paginas.index.solutions.title' }))}</h1>
        </header>
        <div>
          <p>{parse(intl.formatMessage({ id: 'paginas.index.solutions.p1' }))}</p>
          <p>{parse(intl.formatMessage({ id: 'paginas.index.solutions.p2' }))}</p>
          <p>{parse(intl.formatMessage({ id: 'paginas.index.solutions.p3' }))}</p>
          <p>{parse(intl.formatMessage({ id: 'paginas.index.solutions.p4' }))}</p>
          <div className='row container'>
            <div className='col-md-10 col-8 p-0'>
              <p>{parse(intl.formatMessage({ id: 'paginas.index.solutions.p5' }))}</p>
              <Link to="/nossa-essencia/" className="text-lwart-blue" ><u>{parse(intl.formatMessage({ id: 'paginas.index.solutions.link' }))}</u></Link>
            </div>
            <div className='col-md-2 col-4 p-0 position-relative'>
              <div className={`position-absolute ${styles.homeInfo}`} onMouseEnter={() => ShowMore('economiaCircular')} onMouseLeave={() => ShowMore()}>
                <p className='text-white text-center'>{parse(intl.formatMessage({ id: 'paginas.index.solutions.blue_circle' }))}</p>
              </div>
              {showMore === 'economiaCircular' &&
                <div className={`position-absolute text-center ${intl.locale === 'es' ? styles.homeInfoHoverES : styles.homeInfoHover}`}>
                  <img src={circleArrow} />
                  <p className='text-white pt-1'>{parse(intl.formatMessage({ id: 'paginas.index.solutions.green_circle' }))}
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const LwartEmNumeros = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <section className="bg-lwart-darkblue full m-0">
      <div className="container py-5 overflow-hidden">
        <div className="row">
          <motion.div ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, x: 0, position: 'relative' },
              hidden: { opacity: 1, x: -100, position: 'absolute' }
            }} className={`col-lg-3 col-md-6 ${styles.lwartEmNumero} justify-content-center`}>
            <div className="mx-auto d-lg-flex align-items-center d-none">
              <span className='font-size-2 text-white font-Spinnaker d-none d-lg-block' >{parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.desktop.title' }))}</span>
            </div>
            <span className='font-size-15 text-nowrap text-white font-Spinnaker d-block d-lg-none'>{parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.mobile.title' }))}</span>
          </motion.div>
          <motion.div ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1, x: 0, position: 'relative' },
              hidden: { opacity: 1, x: -100, position: 'absolute' }
            }} className={`col-lg-3 col-md-6 ${styles.lwartEmNumero}`}>
            <div className="d-none d-lg-block">
              <span className={styles.lwartInfo}>200</span><br />
              <span className={styles.lwartInfoDescription}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_1' }))}
              </span>
            </div>
            <div className="d-block px-5 d-lg-none d-flex align-items-center justify-content-start">
              <span className={`mr-4 ${styles.lwartInfo}`}>200</span><br />
              <span className={styles.lwartInfoDescription}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_1' }))}
              </span>
            </div>
          </motion.div>
          <motion.div ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 1.2 }}
            variants={{
              visible: { opacity: 1, x: 0, position: 'relative' },
              hidden: { opacity: 1, x: -100, position: 'absolute' }
            }} className={`col-lg-3 col-md-6 ${styles.lwartEmNumero}`}>
            <div className="d-none d-lg-block">
              <span className={styles.lwartInfo}><span className="font-size-17 font-Spinnaker"> {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_2.span1' }))}</span>70</span><br />
              <span className={styles.lwartInfoDescription}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_2.span2' }))}
              </span>
            </div>
            <div className="d-block px-5 d-lg-none d-flex align-items-center justify-content-start">
              <span className={`ml-1 mr-3 ${styles.lwartInfo}`}><span className="font-size-15 font-Spinnaker"> {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_2.span1' }))}</span ><span className="font-size-3 font-Spinnaker">70</span></span><br />
              <span className={styles.lwartInfoDescription}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_1' }))}
              </span>
            </div>
          </motion.div>
          <motion.div ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 1.4 }}
            variants={{
              visible: { opacity: 1, x: 0, position: 'relative' },
              hidden: { opacity: 1, x: -100, position: 'absolute' }
            }} className={`col-lg-3 col-md-6 ${styles.lwartEmNumero} mb-0`}>
            <div className="d-none d-lg-block">
              <span className={styles.lwartInfo}>1ª</span><br />
              <span className={styles.lwartInfoDescription}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_3' }))}
              </span>
            </div>
            <div className="d-block px-5 d-lg-none d-flex align-items-center justify-content-start">
              <span className={`mr-4 ml-5 ${styles.lwartInfo}`}>1ª</span><br />
              <span className={`ml-2 ${styles.lwartInfoDescription}`}>
                {parse(intl.formatMessage({ id: 'paginas.index.lwart_em_numeros.number_3' }))}
              </span>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

const HomeServices = (data) => {
  const intl = useIntl()

  return (
    <section className="full m-0" id={styles.Services}>
      <div className="container py-lg-5 pt-5 px-0">
        <header className="mb-3">
          <h2 className="text-lwart-darkblue text-center mb-0">{parse(intl.formatMessage({ id: 'paginas.index.home_services.title' }))}</h2>
        </header>
        <div className="row p-lg-0 px-4 px-lg-0 mx-0">
          <div className="col-md-4 mb-lg-0 mb-lg-5 p-0 px-lg-3">
            <Link to="/coleta-de-oleo-lubrificante-usado/" className="text-decoration-none">
              <CardService icon={icon1} cardImage={data.card1.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-blue'}
                title={parse(intl.formatMessage({ id: 'paginas.index.home_services.coleta.title' }))}
                text={parse(intl.formatMessage({ id: 'paginas.index.home_services.coleta.text' }))}>
              </CardService>
            </Link>
          </div>
          <div className="col-md-4 mb-lg-0 mb-lg-5 p-0 px-lg-3">
            <Link to="/oleos-de-alta-performance/" className="text-decoration-none">
              <CardService icon={icon2} cardImage={data.card2.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-darkblue'}
                title={parse(intl.formatMessage({ id: 'paginas.index.home_services.oleo_basico.title' }))}
                text={parse(intl.formatMessage({ id: 'paginas.index.home_services.oleo_basico.text' }))}>
              </CardService>
            </Link>
          </div>
          <div className="col-md-4 mb-lg-0 mb-lg-5 p-0 px-lg-3">
            <Link to="/gestao-de-residuos/" className="text-decoration-none">
              <CardService icon={icon3} cardImage={data.card3.childImageSharp.gatsbyImageData} bgColor={'bg-lwart-green'}
                title={parse(intl.formatMessage({ id: 'paginas.index.home_services.gestao_residuos.title' }))}
                text={parse(intl.formatMessage({ id: 'paginas.index.home_services.gestao_residuos.text' }))}>
              </CardService>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

const InfinityCicle = (data) => {
  const intl = useIntl()

  const Steps = (props) => {
    return (
      <>
        <div>
          <div>
            <h3 className="font-size-15 text-lwart-darkblue">
              <span className="font-size-2 font-weight-bold font-Spinnaker">{props.step} </span>
              {props.title}
            </h3>
          </div>
          <div>
            <p className="pr-4">{props.text}</p>
          </div>
        </div>
      </>
    )
  }

  return <>
    <section className="bg-light-green full m-0">
      <div className="py-5 d-lg-block d-none">
        <div>
          <header>
            <h2 className="text-lwart-darkblue text-center">{parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.title' }))}</h2>
          </header>
        </div>
        <div className="container">
          {intl.locale === 'pt'
            ? <img src={infografico} className="w-100" alt="InfoGrafico" />
            : intl.locale === 'en'
              ? <img src={infograficoEnglish} className="w-100" alt="InfoGrafico" />
              : <img src={infograficoEspanhol} className="w-100" alt="InfoGrafico" />
          }
        </div>
      </div>

      <div className="py-5 d-lg-none d-block">
        <div>
          <header>
            <h2 className="text-lwart-darkblue text-center px-4">{parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.title' }))}</h2>
          </header>
        </div>
        <div className="container px-4">
          <GatsbyImage
            image={data.InfograficoMobile.childImageSharp.gatsbyImageData}
            className="img-fluid"
            style={{ width: '700px', height: '260px' }}
            alt="InfoGrafico" />
          <Steps
            step={'1'}
            title={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.01.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.01.text' }))}
          />
          <Steps
            step={'2'}
            title={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.02.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.02.text' }))}
          />
          <Steps
            step={'3'}
            title={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.03.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.03.text' }))}
          />
          <Steps
            step={'4'}
            title={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.04.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.04.text' }))}
          />
          <Steps
            step={'5'}
            title={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.05.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.index.infinite_cycle.05.text' }))}
          />
        </div>
      </div>
    </section>

  </>
}

const KnowOurInitiatives = (data) => {
  const intl = useIntl()

  return (
    <section className="full my-0" style={{ backgroundColor: '#006afc' }}>
      <div className="container py-5 px-lg-5">
        <header className="text-center text-white mb-5">
          <h2 className={`text-white font-size-25 mb-3 ${styles.customMobileFontHome}`}>{parse(intl.formatMessage({ id: 'paginas.index.know_our_initiatives.header.title' }))}</h2>
          <a href="/sustentabilidade/" target="_blank" rel="noreferrer" className='text-white'><span>{parse(intl.formatMessage({ id: 'paginas.index.know_our_initiatives.header.span' }))}</span></a>
        </header>
        {/* <a href="https://www.liderajovem.org/" target="_blank" rel="noreferrer"> */}
        <div id="small-carousel-swipper" className="px-lg-4 mx-lg-2">
          <Swiper {...params}>
            <div className="position-relative">
              <div className={`d-flex align-items-center justify-content-center ${styles.ourEssenceTextImage}`}>
                <GatsbyImage
                  image={data.liderajovem.childImageSharp.gatsbyImageData}
                  className={styles.ourEssenceImage} />
              </div>
              <GatsbyImage
                image={data.inciativa1.childImageSharp.gatsbyImageData}
                imgStyle={{ objectPosition: '50% 25%' }}
                className={styles.customSizeHome1} />
            </div>
            <div className="position-relative">
              <div className={`d-flex align-items-center justify-content-center ${styles.ourEssenceTextImage} ${styles.ourEssenceText}`}>
                <span className={`${styles.ourEssenceText} font-Spinnaker font-size-2 text-lwart-darkblue`}>{parse(intl.formatMessage({ id: 'paginas.index.know_our_initiatives.slide2' }))}</span>
              </div>
              <GatsbyImage
                image={data.inciativa2.childImageSharp.gatsbyImageData}
                className={styles.customSizeHome1} />
            </div>
            <div className="position-relative">
              <div className={`d-flex align-items-center justify-content-center ${styles.ourEssenceTextImage} `}>
                <span className={`${styles.ourEssenceText} font-Spinnaker font-size-2 text-lwart-darkblue`}>{parse(intl.formatMessage({ id: 'paginas.index.know_our_initiatives.slide3' }))}</span>
              </div>
              <GatsbyImage
                image={data.inciativa3.childImageSharp.gatsbyImageData}
                className={styles.customSizeHome1} />
            </div>
          </Swiper>
        </div>
        {/* </a> */}
      </div>
      <div className="gradient-blue-to-green"></div>
    </section>
  )
}

// const Gif = () => {
//   const [showElement, setShowElement] = useState(false)

//   function closeFunction () {
//     setShowElement(!showElement)
//     window.localStorage.setItem('vistoGif', true)
//   }

//   useEffect(() => {
//     setTimeout(() => {
//       setShowElement(true)
//     }, 0)
//   }, [])

//   return (
//     <>
//       <div>
//         {typeof window !== 'undefined' && window.localStorage.getItem('vistoGif') === 'true'
//           ? <div className='d-none'></div>
//           : <div>
//             {showElement === true
//               ? <>
//                 <img src='https://lwart-static-assets-prd.s3.amazonaws.com/Banner_Stock_Car_Lwart_V4.gif' className={styles.gifCar} />
//                 <div className={styles.blueBackground}>
//                   <div onClick={() => closeFunction()} className={styles.iconClose}><img src={close}></img></div>
//                 </div>
//               </>
//               : <div className='d-none'></div>
//             }
//           </div>
//         }
//       </div>
//     </>

//   )
// }

// const Gif = () => {
//   const [showElement, setShowElement] = useState(false)

//   function closeFunction () {
//     setShowElement(!showElement)
//     window.localStorage.setItem('vistoGif', true)
//   }

//   useEffect(() => {
//     setTimeout(() => {
//       setShowElement(true)
//     }, 0)
//   }, [])

//   return (
//     <>
//       <div>
//         {typeof window !== 'undefined' && window.localStorage.getItem('vistoGif') === 'true'
//           ? <div className='d-none'></div>
//           : <div>
//             {showElement === true
//               ? <>
//                 <img src='https://lwart-static-assets-prd.s3.amazonaws.com/Banner_Stock_Car_Lwart_V4.gif' className={styles.gifCar} />
//                 <div className={styles.blueBackground}>
//                   <div onClick={() => closeFunction()} className={styles.iconClose}><img src={close}></img></div>
//                 </div>
//               </>
//               : <div className='d-none'></div>
//             }
//           </div>
//         }
//       </div>
//     </>

//   )
// }

const Index = ({ data }) => {
  return (
    <>
      {/* <Gif /> */}
      <Pagina pagina={data.pagina} />
      {/* <Gif {...data} /> */}
      <FloatingInfo />
      <div className='position-relative'>
        <HomeCarousel {...data} />
        <Solutions />
      </div>
      <LwartEmNumeros />
      <HomeServices {...data} />
      <InfinityCicle {...data} />
      <KnowOurInitiatives {...data} />
    </>
  )
}

export default Index

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  Carrossel1: file(relativePath: {eq: "home/carousel/carousel-1-sustentabilidade.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerPrincipalMobile: file(relativePath: {eq: "home/carousel/sustentabilidade-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Carousel1: file(relativePath: {eq: "home/carousel/carousel-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Carousel3: file(relativePath: {eq: "home/carousel/carousel-7.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Carousel3Mobile: file(relativePath: {eq: "home/carousel/carousel-7-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Carousel1Mobile: file(relativePath: {eq: "home/carousel/home.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Carousel2: file(relativePath: {eq: "home/carousel/carousel-6.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Infografico: file(relativePath: {eq: "home/info-w-text.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  InfograficoMobile: file(relativePath: {eq: "home/infografico.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card1: file(relativePath: {eq: "home/cards/card-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card2: file(relativePath: {eq: "home/cards/card-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  card3: file(relativePath: {eq: "home/cards/card-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  inciativa1: file(relativePath: {eq: "sustentabilidade/instituto/lidera9.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  inciativa2: file(relativePath: {eq: "home/Cultura.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  inciativa3: file(relativePath: {eq: "home/LH4_5044.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  lwart48Anos: file(relativePath: {eq: "home/lwart48anos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  liderajovem: file(relativePath: {eq: "sustentabilidade/face.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 300, height: 300, layout: CONSTRAINED)
    }
  }
}
`
